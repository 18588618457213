import React from "react"
import { graphql } from "gatsby"
import {
  Gallery as GalleryComponent,
  Hero,
  CustomerGuarantee,
} from "../components/PageSpecific/gallery"
import { PageLayout } from "../components/Global/Layouts"
import { SiteMetadata } from "../components/Shared"

const GalleryPage = ({ data }) => {
  const { hero, gallery, guarantee } = data
  return (
    <PageLayout>
      <SiteMetadata title="Gallery" description="Annmade Gallery" />
      <Hero data={hero} />
      <GalleryComponent gallery={gallery} />
      <CustomerGuarantee data={guarantee} />
    </PageLayout>
  )
}

export default GalleryPage

export const query = graphql`
  {
    hero: contentfulPageHero(uuid: { eq: "gallery-hero" }) {
      header
      description {
        description
      }
      primaryCtaText
      primaryUrl
      secondaryCtaText
      secondaryUrl
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    gallery: contentfulGallery(page: { eq: "gallery" }) {
      images {
        id
        createdAt
        localFile {
          url
        }
        fixed(quality: 100) {
          base64
          tracedSVG
          aspectRatio
          srcWebp
          srcSetWebp
        }
      }
    }
    guarantee: contentfulBasicText(uuid: { eq: "customer-guarantee" }) {
      body {
        body
      }
      title
    }
  }
`
