import React, { useState, useEffect } from "react"
import Gallery from "react-photo-gallery"
import { GalleryTransform } from "../../../transforms/GalleryTransform"
import { AiOutlineLoading3Quarters } from "react-icons/ai"

const MainGallery = ({ gallery }) => {
  const [loaded, setLoaded] = useState(false)
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    setPhotos(GalleryTransform(gallery.images))
    const transformPhotos = async () => {
      setPhotos(await GalleryTransform(gallery.images))
      setLoaded(true)
    }
    transformPhotos()
  }, [gallery.images])
  return (
    <div class="bg-white">
      <div class="container xl:max-w-7xl mx-auto px-4 pb-16 lg:px-8 lg:pb-32">
        <div
          className={`m-0-auto
        w-full sm:w-full md:w-full lg:w-10/12
        max-w-4xl
    `}
        >
          {loaded && photos?.length > 0 && (
            <Gallery photos={photos} direction={"column"} />
          )}
          {!loaded && (
            <div className="flex flex-col items-center justify-center">
              <AiOutlineLoading3Quarters className="animate-spin text-gray-600" />
              <h3 className="text-base text-gray-600">Loading...</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainGallery
