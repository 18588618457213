import createImage from "../utilities/image/createImage"

export const GalleryTransform = data => {
  //Set the scale needed for react-gallery
  const dimensionsCalc = (width, height) => {
    let d = {
      width: 1,
      height: 1,
    }
    return new Promise(res => {
      if (width > height) {
        d = {
          width: 4,
          height: 3,
        }
      } else if (width < height) {
        d = {
          width: 3,
          height: 4,
        }
      }
      res(d)
    })
  }

  let transformData = async () => {
    return Promise.all(
      data.map(async photo => {
        let { localFile, id, createdAt } = photo
        let { url } = localFile
        let newObj = {
          id: id,
          src: url,
          timestamp: createdAt,
        }
        let image = await createImage(url)
        let val = await dimensionsCalc(image.naturalWidth, image.naturalHeight)
        let returnObj = { ...newObj, ...val }
        return Promise.resolve(returnObj)
      })
    )
  }

  return transformData().then(res => {
    let newArr = res.filter(val => {
      if (val === undefined) {
        return false
      }
      return true
    })
    console.log(newArr)
    //Gallery limit
    return newArr.sort((a, b) => {
      let aDate = new Date(a.timestamp)
      let bDate = new Date(b.timestamp)
      return bDate.getTime() - aDate.getTime()
    })
  })
}
